.modal{
  :global{
    .ant-form-horizontal .ant-form-item-label{
      min-width: 70px;
      text-align: left;
    }
    .ant-form-item{
      margin: 5px 0;
    }
    .cityLine{
      display: flex;
      align-items: flex-start;
      .ant-form-item{
        width: 100%;
        margin-right: 5px;
      }
      &__stops{
        align-items: flex-start;
        display: flex;
        .ant-form-item{
          min-width: 80px;
          max-width: 80px;
        }
      }
      &__stops-desc{
        margin-top: 10px;
      }
      &__stops-count{
        width: 95px;
      }
    }
    .rate{
      margin-bottom: 10px;
      .ant-form-item{
        max-width: 50%;

      }
    }
  }
}
