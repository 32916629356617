.logo {
  font-size: 34px;
  font-weight: 600;
  align-items: center;
  display: flex;
  :global {
    .ant-typography {
      color: #FFFFFF;
    }
  }
}